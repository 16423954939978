// 图片域名
const url = 'https://source.se6250.com/';
// 安卓app下载地址
const downloadAndroidAppUrl = 'https://source.se6250.com/download/seller.shopee.shop.apk';
// 苹果app下载地址
const downloadIosAppUrl = 'https://source.se6250.com/download/seller.shopee.shop.mobileconfig';
// api请求
const baseUrl = 'https://api.se6250.com/shop';

// IM 地址
const imBaseUrl = 'wss://im-ws.se6250.com/ws-im';
// IM 图片域名
const wsImages = 'https://source.se6250.com/';
// IM api请求地址
const wsUrl = 'https://im-api.se6250.com/shop';

// 语言转换
const lang = {
    null: 'English',
    English: 'english',
    日本語: 'japanese',
    Português: 'portuguese',
    繁體中文: 'chineseTraditional',
    简体中文: 'chinese',
    Français: 'french',
    Deutsch: 'german',
    Türk: 'turkish',
    Español: 'spanish',
    عربي: 'arabic',
    한국인: 'korean',
    Suomi: 'finland',
    italiano: 'italian',
    แบบไทย: 'thai',
    Nederlands: 'dutch',
}

export default {
    url, baseUrl, lang, downloadAndroidAppUrl, downloadIosAppUrl, wsImages, wsUrl, imBaseUrl
}