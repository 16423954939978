/*
 * @Author: 李耀书
 * @Date: 2022-09-25 23:35:52
 * @LastEditors: g05047
 * @LastEditTime: 2022-12-05 20:13:37
 * @Description: file content
 */
import { loadingNode, loadingClear } from '@/components/loading-custom/loadingjs'
import axios from 'axios'
import Vue from 'vue'
import router from 'vue-router'
import routers from '../router/index'
import baseUrl from '../components/component/js/imgurlApi'
import languageType from "@/components/component/js/language";
import { Dialog } from 'vant';
import { Toast } from 'vant'
Vue.use(router)
let messageLoading;
let dialogLanguage
const ServerRequestUrl = baseUrl.baseUrl
// 创建axios实例
const service = axios.create({
  baseURL: ServerRequestUrl,
  timeout: 5000 * 2 // 仅为这个请求设置超时时间为5秒
  // timeout: 1000 // 仅为这个请求设置超时时间为5秒
})
messageLoading =
  Vue.nextTick(() => {
    messageLoading = languageType(localStorage.getItem("languageType")).messageLanguage.loadingTitle
    dialogLanguage = languageType(localStorage.getItem("languageType")).homeLanguage.sure
  })
window.addEventListener("_countNum", (val) => {
  messageLoading = languageType(localStorage.getItem("languageType")).messageLanguage.loadingTitle
  dialogLanguage = languageType(localStorage.getItem("languageType")).homeLanguage.sure
});
// request拦截器==>对请求参数做处理
let loadingCount = 0
service.interceptors.request.use(config => {
  loadingCount += 1
  // loadingNode(2500, "messageLoading messageLoading" + '...')
  loadingNode(2500, messageLoading + '...')
  // Toast.loading({
  //   forbidClick: true,
  //   duration: 0,
  //   message: messageLoading + '...'
  // })
  config.headers.Authorization = window.localStorage.getItem('authentication');
  config.headers['Accept-Language'] = baseUrl.lang[window.localStorage.getItem('languageType')]
  return config
}, error => { // 请求错误处理
  Promise.reject(error)
})
// respone拦截器==>对响应做处理
service.interceptors.response.use(response => { // 成功请求到数据
  // 这里根据后端提供的数据进行对应的处理
  loadingCount--
  if (response.data.code === 200) {
    if (loadingCount == 0) {
      loadingClear(0)
    }

  } else if (response.data.code == 1002) {
    
    Toast.fail({
      message: response.data.message,
      forbidClick: true,
    })
    localStorage.removeItem('authentication');
    routers.replace({
      path: '/login',
    })
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
  } else if (response.data.code == 500) {
    dialogCall(response.data.message)
  } else {
  
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
    Toast.fail({
      message: response.data.message,
      forbidClick: true,
    })
  }
  return response.data
},
  err => {
    loadingCount--
    if (loadingCount == 0) {
      loadingClear(0)
      Toast.clear()
    }
    const text = JSON.parse(JSON.stringify(err))
    let errText
    if (text.code === 'ECONNABORTED') {
      errText = "The request has timed out. Click Refresh to try again!"
      // 处理中止错误
    } else {
      errText = "Service abnormality, click refresh to try again!"
    }
    dialogCall(errText)
    return Promise.reject(err)
  }
)
function dialogCall (message) {
  
  Dialog.confirm({
    className: 'axios-dialog-class',
    message: message,
    showCancelButton: false,
    confirmButtonText: dialogLanguage,
    beforeClose
  })
    // .then(() => {
    //   location.reload();
    // })
    // .catch(() => {

    // });
}
function beforeClose(action, done) {
  if (action === 'confirm') {
    setTimeout(()=>{
      location.reload();
    },1000)
  } else {
    done();
  }
}
export default service