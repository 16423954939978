import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import 'lib-flexible/flexible'
import '../src/utils/vant'
import './utils/appback.js'
import './utils/version'
import global_msg from './components/component/js/global'
import VueScroll from '@david-j/vue-j-scroll';
Vue.use(VueScroll)
import VueClipboard from 'vue-clipboard2' //引入插件
Vue.use(VueClipboard) //安装插件
Vue.prototype.$globalCommon = global_msg;
Vue.config.productionTip = false
import socketApi from "./utils/websocket"
let time = 0
export default timeDate => {
  setTimeout(({
    time: 1
  }), 6000)
  return time
}
if (process.env.NODE_ENV !== 'development') {
  console.log = function () { }
  console.error = function () { }
}
Vue.prototype.$socketApi = socketApi;//websocket挂在原型上
//在vue原型上main绑定方法
Vue.prototype.$addStorageEvent = function (type, key, data) {
  if (type === 1) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('websotck', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  } else if (type === 3) {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('register', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  } else if (type === '_countNum') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent(type, false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  }
  else {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('unreadNumber', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  }
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')